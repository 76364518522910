import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import NavLinks from "../components/NavLinks"

type Props = {
  isLoading: boolean
  handleActive: (section: string) => void
}

const Navbar = (props: Props) => {
  const { t } = useTranslation()
  const [openNav, setOpenNav] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrolled(e.target.documentElement.scrollTop > 100)

      let scrollTop = window.pageYOffset
      let winHeight = window.innerHeight
      let docHeight = getMaxWindowHeight(e.target)

      let totalDocScrollLength = docHeight - winHeight
      let scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100)
      e.target.documentElement.style.setProperty(
        "--scroll",
        scrollPostion + "%"
      )
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrolled])

  const getMaxWindowHeight = (element: any) => {
    return Math.max(
      element.body.scrollHeight,
      element.documentElement.scrollHeight,
      element.body.offsetHeight,
      element.documentElement.offsetHeight,
      element.body.clientHeight,
      element.documentElement.clientHeight
    )
  }

  return (
    <nav className={scrolled ? "scrolled" : ""}>
      <div className="container menu">
        <div className="brand">
          <a>{t("nav.brand")}</a>
        </div>
        <div
          onClick={() =>{ 
            setOpenNav(!openNav)
            document.documentElement.style.setProperty("--scrollEnabled", openNav?"visible":"hidden")
          }}
          className={`menu-icon ${openNav ? "cross" : ""}`}
        >
          <svg height="50" width="50" viewBox="0 0 50 50">
            <line
              x1="13"
              y1="16.5"
              x2="37"
              y2="16.5"
              stroke="black"
              strokeWidth="3"
            />
            <line
              className="cross-l"
              x1="13"
              y1="24.5"
              x2="37"
              y2="24.5"
              stroke="black"
              strokeWidth="3"
            />
            <line
              className="cross-r"
              x1="13"
              y1="24.5"
              x2="37"
              y2="24.5"
              stroke="black"
              strokeWidth="3"
            />
            <line
              x1="13"
              y1="32.5"
              x2="37"
              y2="32.5"
              stroke="black"
              strokeWidth="3"
            />
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <div className={`menu-desktop menu-phone ${openNav ? "open" : ""}`}>
          <NavLinks
            isLoading={props.isLoading}
            closeMenu={() => setOpenNav(false)}
            handleActive={props.handleActive}
          />
        </div>
      </div>
      <div className="progress"></div>
    </nav>
  )
}

export default Navbar
