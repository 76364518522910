import React from "react"
import { useTranslation } from "react-i18next"

import Timeline from "../components/Timeline"

type Props = { isResumeSection: boolean }

const About = (props: Props) => {
  const { t } = useTranslation()

  const timelineData = [
    {
      title: t("resume.education.title"),
      customClass: "left",
      items: [
        {
          title: t("resume.education.first.title"),
          date: t("resume.education.first.date"),
          group: t("resume.education.first.institute"),
          text: t("resume.education.first.text"),
        },
        {
          title: t("resume.education.second.title"),
          date: t("resume.education.second.date"),
          group: t("resume.education.second.institute"),
          text: t("resume.education.second.text"),
        },
        {
          title: t("resume.education.third.title"),
          date: t("resume.education.third.date"),
          group: t("resume.education.third.institute"),
          text: t("resume.education.third.text"),
        },
      ],
    },
    {
      title: t("resume.experience.title"),
      customClass: "right",
      items: [
        {
          title: t("resume.experience.first.title"),
          date: t("resume.experience.first.date"),
          group: t("resume.experience.first.enterprise"),
          text: t("resume.experience.first.text"),
        },
        {
          title: t("resume.experience.second.title"),
          date: t("resume.experience.second.date"),
          group: t("resume.experience.second.enterprise"),
          text: t("resume.experience.second.text"),
        },
        {
          title: t("resume.experience.third.title"),
          date: t("resume.experience.third.date"),
          group: t("resume.experience.third.enterprise"),
          text: t("resume.experience.third.text"),
        },
      ],
    },
  ]

  return (
    <>
      <section id="resume">
        <div className="container">
          {timelineData.map(timeline => (
            <Timeline
              title={timeline.title}
              items={timeline.items}
              customClass={timeline.customClass}
              isResumeSection={props.isResumeSection}
            />
          ))}
        </div>
      </section>
    </>
  )
}

export default About
