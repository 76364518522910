import React from "react"

type Props = { title: string }

const About = (props: Props) => {
  return (
    <div className="section-title">
      <h2 data-shadow={props.title}>{props.title}</h2>
    </div>
  )
}

export default About
