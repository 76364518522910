import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  title: string
  description: string
  image: any
  linkUrl: string
}

const Project = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className="project-card">
      <div className="project-card-thumb">
        <img
          src={props.image}
          alt={props.title}
          className="project-card-image"
        ></img>
        <div className="project-card-caption">
          <h2 className="project-card-title">{props.title}</h2>
          <p className="project-card-snippet">{props.description}</p>
          {props.linkUrl && (
            <a
              href={props.linkUrl}
              className="project-card-button"
              target="_blank"
            >
              {t("projects.button")}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Project
