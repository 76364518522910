import React from "react"

type Props = { animate: boolean; title: string; percentage: string }

const SkillBar = (props: Props) => {
  return (
    <div className="skill" key={props.title}>
      <div className="name">{props.title}</div>
      <div className="percentage">{props.percentage}</div>
      <div className="progress">
        <div
          className="fill-progress"
          style={{
            width: props.animate ? props.percentage : "0%",
          }}
        ></div>
      </div>
    </div>
  )
}

export default SkillBar
