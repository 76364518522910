import React, { useState } from "react"
import { I18nextProvider } from "react-i18next"
import Helmet from "react-helmet"

import i18n from "../locale/i18n"
import SplashScreen from "../components/SplashScreen"
import Navbar from "../sections/Navbar"
import Intro from "../sections/Intro"
import About from "../sections/About"
import Resume from "../sections/Resume"
import Projects from "../sections/Projects"
import Footer from "../sections/Footer"

import BackButton from "../components/BackButton"

import {
  START_REF,
  ABOUT_REF,
  PROJECTS_REF,
  RESUME_REF,
} from "../utils/Constants"

import "./../style/main.scss"

const Index = () => {
  const [loading, setLoading] = useState(true)
  const [showBackButton, setShowBackButton] = useState(false)
  const [isAboutSection, setAboutSection] = useState(false)
  const [isResumeSection, setResumeSection] = useState(false)
  const [isProjectsSection, setProjectsSection] = useState(false)

  const handleActive = (section: string) => {
    if (section === ABOUT_REF) setAboutSection(true)
    if (section === RESUME_REF) setResumeSection(true)
    if (section === PROJECTS_REF) setProjectsSection(true)

    setShowBackButton(section !== START_REF)
  }

  setTimeout(() => {
    document.documentElement.style.setProperty("--scrollEnabled", "visible")
    setLoading(false)
  }, 3000)

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <title>{i18n.t("web.title")}</title>
      </Helmet>
      <SplashScreen isLoading={loading} />
      <Navbar isLoading={loading} handleActive={handleActive} />
      <Intro isLoading={loading} />
      <About isAboutSection={isAboutSection} />
      <Resume isResumeSection={isResumeSection} />
      <Projects isProjectsSection={isProjectsSection} />
      <Footer handleActive={handleActive} />
      {showBackButton && <BackButton />}
    </I18nextProvider>
  )
}

export default Index
