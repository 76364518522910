import i18n from "i18next"
import XHR from "i18next-xhr-backend"

import es from "./langs/es.json"

i18n.use(XHR).init({
  debug: false,
  lng: "es",
  fallbackLng: "es",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    es: {
      translations: es,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
})

export default i18n
