import React from "react"
import { useTranslation } from "react-i18next"

import { Link as NavLink } from "react-scroll"
import {
  START_REF,
  ABOUT_REF,
  PROJECTS_REF,
  RESUME_REF,
} from "../utils/Constants"

type Props = {
  isLoading: boolean
  handleActive: (section: string) => void
  closeMenu?: () => void
}

const NavLinks = (props: Props) => {
  const { t } = useTranslation()

  const offsetAdjust = -200

  const navConfig = [
    { ref: START_REF, title: t("nav.start"), offset: offsetAdjust },
    { ref: ABOUT_REF, title: t("nav.about"), offset: offsetAdjust },
    { ref: RESUME_REF, title: t("nav.resume"), offset: offsetAdjust },
    { ref: PROJECTS_REF, title: t("nav.projects"), offset: offsetAdjust },
  ]

  return (
    <ul className="nav-container">
      {navConfig.map(link => (
        <li
          key={link.ref}
          className={`nav-item ${!props.isLoading ? "animate" : ""}`}
        >
          <NavLink
            activeClass="active"
            to={link.ref}
            spy={true}
            smooth={true}
            duration={500}
            onClick={props.closeMenu}
            offset={link.offset}
            isDynamic={false}
            onSetActive={props.handleActive}
          >
            {link.title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default NavLinks
