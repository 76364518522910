import React from "react"
import { useTranslation } from "react-i18next"
import { scroller } from "react-scroll"

import { ABOUT_REF } from "../utils/Constants"

type Props = { isLoading: boolean }

const Intro = (props: Props) => {
  const { t } = useTranslation()

  const scrollAbout = () => {
    scroller.scrollTo(ABOUT_REF, { duration: 1500, smooth: true })
  }

  return (
    <div id="start" className="header">
      <div className="overlay"></div>
      <div className="container intro">
        <h1 className={`name ${!props.isLoading ? "animate" : ""}`}>
          {t("intro.title")}
        </h1>
        <h6 className={`profession  ${!props.isLoading ? "animate" : ""}`}>
          {t("intro.subtitle")}
        </h6>
      </div>
      <div className="scroll-indicator">
        <a onClick={scrollAbout}>
          <span></span>
        </a>
      </div>
    </div>
  )
}

export default Intro
