import React from "react"
import { animateScroll } from "react-scroll"

const BackButton = () => {
  const scrollToTop = () => {
    animateScroll.scrollToTop({ duration: 1000, smooth: true })
  }

  return (
    <a className={'arrow-up'} onClick={scrollToTop}>
      <span className="left-arm"></span>
      <span className="right-arm"></span>
      <span className="arrow-slide"></span>
    </a>
  )
}

export default BackButton
