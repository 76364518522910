import React from "react"
import { animateScroll } from "react-scroll"
import { useTranslation } from "react-i18next"
import NavLinks from "../components/NavLinks"
import { ReactSVG } from "react-svg"
import { LINKDN_URL } from "../utils/Constants"

type Props = { handleActive: (section: string) => void }

const Footer = (props: Props) => {
  const { t } = useTranslation()
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-block">
          <div className="footer-brand">{t("footer.brand")}</div>
          <NavLinks handleActive={props.handleActive} />
          <div className="footer-social">
            <a href={LINKDN_URL} target="_blank">
              <ReactSVG src="linkdn.svg" />
            </a>
          </div>
        </div>
        <div className="footer-signature">{t("footer.signature")}</div>
      </div>
    </footer>
  )
}

export default Footer
