import React from "react"
import { useTranslation } from "react-i18next"

import TimelineItem from "./TimelineItem"

type Props = {
  title: string
  items: { title: string; date: string; group: string; text: string }[]
  customClass: string
  isResumeSection: boolean
}

const About = (props: Props) => {
  return (
    <div className="timeline">
      <div className="timeline-header">
        <h3>{props.title}</h3>
      </div>
      <ul
        className={`timeline-section ${props.customClass} ${
          props.isResumeSection ? "show" : ""
        }`}
      >
        {props.items.map(item => (
          <TimelineItem
            title={item.title}
            date={item.date}
            group={item.group}
            text={item.text}
          />
        ))}
      </ul>
    </div>
  )
}

export default About
