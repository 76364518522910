import React from "react"
import { useTranslation } from "react-i18next"

import SkillBar from "../components/SkillBar"

import profileImage from "../resources/images/profile.jpg"

type Props = { isAboutSection: boolean }

const About = (props: Props) => {
  const { t } = useTranslation()
  const skills = [
    { title: t("about.skill-01"), percentage: "95%" },
    { title: t("about.skill-02"), percentage: "70%" },
    { title: t("about.skill-03"), percentage: "95%" },
    { title: t("about.skill-04"), percentage: "85%" },
  ]

  return (
    <section id="about">
      <div className="profile-picture">
        <img src={profileImage} />
      </div>
      <div className="about-info">
        <div className="about-text">
          <h3>{t("about.title")}</h3>
          <p>{t("about.description")}</p>
        </div>
        <div className="main-skills">
          {skills.map(skill => (
            <SkillBar
              animate={props.isAboutSection}
              title={skill.title}
              percentage={skill.percentage}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default About
