import React from "react"
import { useTranslation } from "react-i18next"

type Props = { title: string; date: string; group: string; text: string }

const About = (props: Props) => {
  return (
    <li className="item">
      <h3 className="title">{props.title}</h3>
      <h5 className="sub-title">
        <span>{props.date}</span>
        <span>{props.group}</span>
      </h5>
      <p>{props.text}</p>
    </li>
  )
}

export default About
