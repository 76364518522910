import React from "react"
import { useTranslation } from "react-i18next"

import Project from "./../components/Project"
import SectionTitle from "./../components/SectionTitle"

import lolCompanion from "../resources/images/projects/lol.png"
import overdata from "../resources/images/projects/overdata.png"
import botshirt from "../resources/images/projects/botshirt.png"

type Props = { isProjectsSection: boolean }

const Projects = (props: Props) => {
  const { t } = useTranslation()

  const projects = [
    {
      title: t("projects.title-01"),
      description: t("projects.description-01"),
      image: botshirt,
    },
    {
      title: t("projects.title-02"),
      description: t("projects.description-02"),
      image: overdata,
    },
    {
      title: t("projects.title-03"),
      description: t("projects.description-03"),
      image: lolCompanion,
      linkUrl:
        "https://play.google.com/store/apps/details?id=com.amfulusapps.leagueoflegends&hl=es_419&gl=US",
    },
  ]

  return (
    <section id="projects">
      <SectionTitle title={t("nav.projects")} />
      <div className="project-list">
        {projects.map((project) => (
          <Project
            title={project.title}
            description={project.description}
            image={project.image}
            linkUrl={project.linkUrl}
          />
        ))}
      </div>
    </section>
  )
}

export default Projects
